@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  --primaryClr: #2b8b30;
  --secondaryClr: #182838;
  --secondaryClr1: #334155;
  --secondaryClr2: #e2e8f0;
  --textClr: rgb(226 232 240/0.7);
  --headClr: #213040;
  --bodyClr: #040b1b;
  --whiteClr: #ffffff;
  --blackClr: #182838;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #bae3ec;
  --oddsLay: #f3c7c8;
  --siteRedClr: #d9534f;
  --siteGreenClr: #27ae60;
}
.light-mode:root {
  --primaryClr: #ffb80c;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

body {
  background: var(--bodyClr);
  color: var(--whiteClr);
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  transition: all 0.3s;
  &:focus,
  &:focus-visible {
    outline: none;
  }
}
.btn-primary {
  background-color: transparent;
  border-color: #fff;
  height: 35px;
  font-size: 14px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 0.4rem;
  padding: 6px 8px;
  &:hover,
  &:focus {
    background-color: #cbd5e1;
  }
}
.btn-secondary {
  color: var(--bodyClr) !important;
  background-color: #cbd5e1;
  height: 35px;

  &:hover {
    color: var(--bodyClr) !important;
    background-color: #cbd5e1 !important;
  }
}
.btn-outline-primary {
  border-color: var(--primaryClr);
  &:hover,
  &:focus {
    background: transparent !important;
    color: #000000 !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-secondary {
  font-size: 11px;
  border-radius: 50px;
  padding: 6px 8px;
}
.back-link,
.back_btn {
  color: var(--whiteClr);
  font-size: 12px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--whiteClr);
  border-radius: 0.15rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: var(--secondaryClr);
    border-color: var(--secondaryClr);
  }
  svg {
    margin-right: 5px;
  }
}
.titleWithBack {
  position: relative;
  border-bottom: 1px solid var(--blackClr);
  .back-link,
  .back_btn {
    position: absolute;
    top: 2px;
    right: 0;
    background: var(--secondaryClr);
    padding: 0.25rem 0.75rem;
    border-color: var(--blackClr);
    opacity: 0.8;
    @media only screen and (max-width: 767.98px) {
      top: -1px;
      height: 28px;
      padding: 0.25rem 0.5rem;
    }
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
img {
  width: 100%;
  height: auto;
}
.all-input {
  position: relative;
}
input,
input.form-control {
  border-radius: 3px;
  border-color: var(--blackClr);
  font-size: 14px;
  font-weight: 300;
  transition: 0.4s;
  position: relative;
  &:focus {
    box-shadow: none !important;
  }
}
input.form-control ~ .focus-border::after,
input.form-control ~ .focus-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;

  background-color: #fb9255;
  transition: 0.3s;
}

input.form-control ~ .focus-border::after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
input.form-control ~ .focus-border i::after,
input.form-control ~ .focus-border i::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #fb9255;
  transition: 0.4s;
}
input.form-control ~ .focus-border i::after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

input.form-control:focus ~ .focus-border i::after,
input.form-control:focus ~ .focus-border i::before {
  height: 100%;
  transition: 0.4s;
}
input.form-control:focus ~ .focus-border::after,
input.form-control:focus ~ .focus-border::before {
  width: 100%;
  transition: 0.3s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--textClr) inset !important;
  -webkit-text-fill-color: var(--bodyClr) !important;
}

body {
  .theme-btn {
    display: none;
    .theme-icon {
      width: 25px;
      height: 25px;
      border: 1px solid var(--whiteClr);
      border-radius: 50%;
      text-align: center;
      line-height: 13px;
      padding: 4px;
      .dark_theme {
        display: none;
      }
    }
  }
  &.light-mode {
    .theme-btn {
      .theme-icon {
        border-color: var(--whiteClr);
        .dark_theme {
          display: block;
        }
        .light_theme {
          display: none;
        }
      }
    }
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  background: var(--bodyClr);
  padding: 0.25rem 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 1px 3px 0 rgba(226, 232, 240, 0.1),
    0 1px 2px -1px rgba(226, 232, 240, 0.1);
  &.aftrlgn {
    padding: 0.25rem 0 0px;
  }
  @media only screen and (max-width: 767.98px) {
    padding-top: 6px;
  }
  .middle-part {
    .navbar {
      .navbar-collapse {
        background-color: transparent !important;
      }
    }
  }
  .leftbar_toggle {
    .btn {
      background: transparent;
      border: 0;
      padding: 0;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 15px;
        height: 15px;
        display: block;
        fill: var(--whiteClr);
      }
      &:hover,
      &:focus {
        background: transparent !important;
      }
    }
  }
  .logo {
    max-width: 125px;
    @media screen and (max-width: 767.98px) {
      max-width: 90px;
    }
    a {
      display: block;
    }
  }
  .navbar {
    padding: 0;
    .navbar-collapse {
      border-radius: 22px;
      .navbar-nav a {
        color: var(--whiteClr);
        font-size: 13px;
        font-weight: 600;
        padding: 0 8px;
        display: flex;
        align-items: center;
        &.exchange-nav div {
          display: flex;
          margin-right: 5px;
          -webkit-animation: sports-book-bouncing 3s linear infinite alternate;
          animation: sports-book-bouncing 3s linear infinite alternate;
          svg,
          img {
            -webkit-animation: sports-book-rotate 3s linear infinite alternate;
            animation: sports-book-rotate 3s linear infinite alternate;
            margin-right: 0px;
          }
        }

        svg,
        img {
          fill: var(--whiteClr);
          width: 20px;
          height: 20px;
          display: none;
          margin-right: 5px;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--whiteClr);
    .balance-web {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #252c3d;
        border-radius: 25px;
        padding: 5px;
        @media only screen and (max-width: 575.98px) {
          justify-content: flex-start;
        }
        li {
          border-right: 1px solid #fff;
          padding: 0 0.75rem;
          font-size: 0.85rem;
          &:last-child {
            border: 0;
          }
          @media only screen and (max-width: 575.98px) {
            padding: 0 4px !important;
            font-size: 11px !important;
            line-height: 14px;
          }
        }
      }
    }
    .searchbar {
      width: 384px;
      margin-right: 1.15rem;
      input {
        padding-left: 35px;
        background: var(--secondaryClr1) !important;
        border-radius: 5px;
        border: 0;
        height: 36px;
        color: #fff !important;
        &::placeholder {
          color: #cbd5e1;
          font-weight: 600;
        }
        span {
          position: absolute;
          width: 16px;
          height: 16px;
          top: 7px;
          left: 10px;
        }
      }
      span {
        position: absolute;
        top: 6px;
        left: 12px;
        width: 16px;
        height: 16px;
      }
    }
    .headicons {
      display: flex;
      align-items: center;
      margin-right: 1.15rem;
      color: #fff;
      margin-left: 10px;
      font-weight: 600;
      font-size: 14px;
      &.support {
        color: #ffffff;
        animation: wiggle 2s linear infinite;
        background: #334155;
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: 0px 2px 6px 0px rgb(118 118 118 / 83%);
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        &.texticon {
          margin-right: 0;
        }
      }
    }
    a.deposit {
      background: var(--siteGreenClr);
      color: var(--whiteClr);
      font-weight: 600;
      width: calc(100% - 20px);
      text-align: center;
      display: block;
      margin: 6px 10px;
      border-radius: 5px;
      padding: 3px 10px;
    }
    .path-stroke {
      stroke: var(--whiteClr);
    }
    .dropdown {
      .btn {
        background: transparent;
        padding: 0;
        border: 0;
      }
      .dropdown-menu {
        min-width: 250px;
        .dropdown-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          h6 {
            margin-bottom: 0;
          }
          span {
            color: #ff5513;
            font-size: 12px;
            font-weight: 600;
          }
          &.deposit {
            background: var(--siteGreenClr);
            color: var(--whiteClr);
            font-weight: 600;
            width: calc(100% - 20px);
            text-align: center;
            display: block;
            margin: 10px;
            border-radius: 5px;
            &:hover {
              background: var(--siteGreenClr);
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
    .funds {
      ul {
        li {
          a {
            color: var(--whiteClr);
            span {
              padding-left: 5px;
            }
            &.deposit {
              background: var(--siteGreenClr);
              font-weight: 600;
              padding: 7px 10px;
              border-radius: 3px;
            }
          }
        }
      }
    }
    .social {
      display: flex;
      align-items: center;
      margin-right: 5px;
      li {
        margin: 0 5px;
        position: relative;
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .menu-btn {
      padding: 0;
      background: none;
      border: none;
      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }
      svg {
        width: 20px;
        height: auto;
        margin-left: 5px;
        fill: var(--whiteClr);
      }
    }
    .btn_signin {
      color: var(--whiteClr);
      border-color: var(--whiteClr);
      background-color: transparent;
      width: 58px;
      height: 29px;
      line-height: 13px;
      font-size: 14px;
      padding: 6px 0 !important;
      // background-image: conic-gradient(
      //   from 1turn,
      //   rgba(88, 175, 16, 1),
      //   rgba(29, 128, 58, 1)
      // );

      &:hover {
        background-color: #cbd5e1;
        color: var(--bodyClr);
        // background-image: conic-gradient(
        //   from 1turn,
        //   rgba(88, 175, 16, 0.7),
        //   rgba(29, 128, 58, 0.7)
        // );
      }
    }
    .btn_signup {
      color: var(--bodyClr);
      background-color: #cbd5e1;
      border-color: #cbd5e1;
      width: 74px;
      height: 29px;
      line-height: 13px;
      font-size: 14px;
      &:hover {
        color: var(--bodyClr) !important;
        background-color: #cbd5e1 !important;
      }
    }
  }
  .bottom_head {
    margin-top: 5px;
    margin-bottom: calc(0.75rem * 0);
    height: 28px;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      overflow-x: auto;
      overflow-y: hidden;
      @media screen and (max-width: 991.98px) {
        justify-content: flex-start;
      }
      li {
        display: inline-block;
        position: relative;
        a {
          color: #fff;
          font-weight: 600;
          text-transform: uppercase;
          display: block;
          cursor: pointer;
          padding: 5px 12px;
          span {
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
            &::before {
              background-color: rgba(226 232 240 / 0.9);
              bottom: 0;
              content: "";
              height: 2px;
              left: 50%;
              position: absolute;
              transform: translateX(-50%);
              transition-duration: 0.3s;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              width: 12px;
            }
          }
          &:hover {
            span::before {
              width: 80% !important;
            }
          }
          img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
            display: none;
          }
        }
        a img.inplay {
          // -webkit-animation: game-category__icon-floating 1.5s infinite
          //   alternate;
          // animation: game-category__icon-floating 1.5s infinite alternate;
          animation: sports-book-bouncing 3s linear infinite alternate;
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}

.logo,
.footer-logo {
  img {
    &.light-theme {
      display: none;
    }
  }
}
.light-mode {
  .logo,
  .footer-logo {
    img {
      &.light-theme {
        display: block;
      }
      &.dark-theme {
        display: none;
      }
    }
  }
}
.light-mode {
  .offcanvas {
    .offcanvas-body {
      .social_links {
        li {
          svg {
            filter: brightness(0);
          }
        }
      }
    }
  }
}
.offcanvas {
  border: none !important;
  z-index: 999999999;
}
.offcanvas-header {
  background: #e2e8f0;
  color: #0f172a;
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--bodyClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--whiteClr);
  }
  .user-info {
    color: #0f172a;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: bold;
      &.phone {
        color: #0f172a;
        font-size: 14px;
        font-weight: normal;
      }
    }
    a {
      color: #0f172a;
    }
  }
}
.offcanvas-body {
  background: var(--whiteClr);
  .offcanvas-head {
    background: #eee;
    padding: 10px 0;
    border-radius: 3px;
  }
  .balance {
    font-size: 16px;
    color: rgba(15 23 42 / 0.6);
    display: flex;
    align-items: center;
    svg {
      margin-right: 14px;
      fill: var(--primaryClr);
      stroke: var(--primaryClr);
    }
  }
  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: rgba(15 23 42 / 0.6);
  }
  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: var(--bodyClr) !important;
    background-color: #cbd5e1;
    border-color: var(--bodyClr);
  }
  .menu-items {
    border-top: 1px solid rgba(243 244 246 / 1);
    margin: 15px -1rem 0;
    li {
      border-bottom: 1px solid #e2e8f0;
      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: rgba(15, 23, 42, 1);
        font-weight: 600;
        img {
          margin-right: 15px;
          width: 24px;
          height: auto;
        }
        svg {
          margin-right: 15px;
          width: 22px;
          height: auto;
        }
      }
    }
  }
  ul.social_links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    li {
      margin: 0 10px;
      position: relative;
      svg {
        fill: var(--bodyClr);
        stroke: var(--bodyClr);
        cursor: pointer;
      }
      .notification-count {
        width: 12px;
        height: 12px;
        background: var(--siteRedClr);
        color: var(--whiteClr);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.form-steps {
  text-align: center;
}
body.leftbat-opened {
  .leftbarSec {
    transform: translateX(0);
  }
}
.mt--14 {
  .leftbarSec {
    margin-top: -14px;
  }
}
.main {
  min-height: calc(100vh - 462px);
  &.login-signup {
    min-height: calc(100vh - 77px);
    @media only screen and (max-width: 767.98px) {
      min-height: calc(100vh - 73px);
    }
  }
  .leftbarSec {
    background: #101010;
    width: 200px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.62);
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (max-width: 1199.98px) {
      position: fixed;
      transform: translateX(-100%);
      transition: all 0.3s;
      z-index: 999;
    }
    @media only screen and (max-width: 767.98px) {
      width: 70%;
      z-index: 99999999;
    }
    .games_link {
      h6 {
        color: var(--primaryClr);
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px 10px;
        border-bottom: 1px solid var(--primaryClr);
      }
      ul {
        // padding: 10px 0;
        li {
          cursor: pointer;
          // border-bottom: 1px solid #333;
          background: #1a1a1a;
          padding: 0px;
          margin: 0 0 8px;
          border-radius: 10px;
          &:hover {
            background: #3f3e3e;
          }
          > div {
            line-height: 35px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            position: relative;
            .animate-img {
              width: inherit !important;
            }
            .animate-img .spin-anim .img-pointer {
              position: absolute;
              top: -7px;
              left: 48%;
            }
            &.inplay {
              img {
                -webkit-animation: sports-book-bouncing 3s linear infinite
                  alternate;
                animation: sports-book-bouncing 3s linear infinite alternate;
              }
            }
            &:hover {
              img {
                -webkit-animation: icon-animation 1.5s infinite alternate;
                animation: icon-animation 1.5s infinite alternate;
                -webkit-animation: icon-animation1 1.5s infinite alternate;
                animation: icon-animation1 1.5s infinite alternate;
              }
            }
            &.no-hover img.img-turntable {
              animation: rotate-e1vxjz6v 2s infinite linear;
            }
            &.no-hover img.img-pointer {
              animation: inherit !important;
              animation: inherit !important;
            }
            img {
              width: 22px;
              height: auto;
            }
            span {
              color: var(--whiteClr);
              font-size: 12px;
              // font-weight: 600;
              text-transform: uppercase;
              margin-left: 10px;
              opacity: 0.8;
            }
            .sport-event-count {
              background: var(--primaryClr);
              color: var(--whiteClr);
              font-size: 10px;
              font-weight: 600;
              width: 20px;
              height: 15px;
              line-height: 16px;
              margin-left: auto;
              padding: 0;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  @-webkit-keyframes icon-animation {
    to {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
    }
  }

  @keyframes icon-animation {
    to {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
    }
  }
  @-webkit-keyframes icon-animation1 {
    to {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }

  @keyframes icon-animation1 {
    to {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }
  .middleContentSec {
    width: 100%;
    background: #323232;
    @media only screen and (min-width: 1200px) {
      width: calc(100% - 600px);
      max-height: calc(100vh - 52px);
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
        width: calc(100% - 500px);
      }
    }
  }
  .rightbarSec {
    background: #101010;
    width: 400px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }
    @media only screen and (max-width: 1199.98px) {
      display: none;
    }
    h1 {
      color: var(--primaryClr);
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    .VSlider {
      background: #1a1a1a;
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;
      .slick-slider {
        position: relative;
        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;
          img {
            border-radius: 5px;
          }
          .overlay {
            position: absolute;
            display: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            border-radius: 5px;
          }
        }
        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;
          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;
              &::before {
                color: var(--whiteClr);
                font-size: 15px;

                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }
            &:first-child {
              button {
                &::before {
                  opacity: 0.6;
                  content: "01";
                }
              }
            }
            &:nth-child(2) {
              button {
                &::before {
                  opacity: 0.6;
                  content: "02";
                }
              }
            }
            &:nth-child(3) {
              button {
                &::before {
                  opacity: 0.6;
                  content: "03";
                }
              }
            }
            &.slick-active {
              button {
                opacity: 0.6;
                background: var(--primaryClr);
                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      a {
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;
          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 90px;
            transition: all 0.4s;
          }
        }
        &:hover {
          .overlay {
            opacity: 0.8;
            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
      .accordion {
        .accordion-item {
          background-color: #1a1a1a;
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              // border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }
              &:hover,
              &:focus {
                background: transparent !important;
              }
              &:after {
                content: none;
              }
            }
          }
          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;
            .bets_box {
              .bet_info {
                .table {
                  background: #a7a7a7;
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #555;
                  thead {
                    background: #555;
                    display: block;
                    tr {
                      th {
                        color: var(--whiteClr);
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      background: #222;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #333;
                      &:hover {
                        background: #444;
                      }
                    }
                    tr {
                      td {
                        color: var(--whiteClr);
                        font-size: 11px;
                        border-color: #333;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;
                          &.lay {
                            background: #72bbef;
                          }
                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no_data {
              color: var(--whiteClr);
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }
          &.live_tv {
            .accordion-body {
              background: #a7a7a7;
              .live_tv_box {
                height: 175px;
              }
            }
          }
          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .match_slider_sec {
    width: calc(100% - 50px);
    margin-left: 25px;
    .match_info_slider {
      .info_box {
        background: #213040;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // height: 128px;
        margin: 0 5px;
        .match {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          .tournament:first-child {
            background: #ff1967;
            height: 30px;
            font-size: 12px;
            padding: 8px;
            color: #fff;
          }
          .tournament:last-child {
            padding-top: 10px;
            font-size: 15px;
          }
        }
        .participants {
          display: flex;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          .time {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0 4px;
            text-align: center;
            font-size: 10px;
          }
          .item {
            // flex: 1;
            // overflow: hidden;
            font-size: 10px;
            text-align: center;
            .flag {
              width: 55px;
              height: 55px;
              line-height: 28px;
              margin: 0 auto;
              border-radius: 50%;
            }
            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .country-name {
          display: flex;
          background: #596285;
          border-top: 2px solid #031c2a;
          .name {
            flex: 1;
            &:first-child {
              background: #3c435d;
              padding: 4px 15px;
              font-size: 12px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            &:last-child {
              padding: 4px 15px;
              background: #596285;
              font-size: 12px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
        .market {
          display: flex;
          gap: 4px;
          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;
            .name {
              color: #abb0ba;
            }
            .odds {
              color: #e7cf90;
            }
          }
        }
      }
      .slick-arrow {
        background: #222430;
        height: 100%;
        &.slick-prev {
          border-radius: 8px 0 0 8px;
        }
        &.slick-next {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
  .popular_game_sec {
    background: transparent;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    // box-shadow: 0 4px 3px rgba(255, 189, 0, 0.6);
    @media screen and (max-width: 767.98px) {
      padding: 1rem 2rem 0.5rem;
    }
    .head-popular {
      font-weight: 600;
      font-size: 1.25rem;
    }
    .game_box {
      margin: 5px;
      a {
        img {
          border-radius: 0.75rem;
        }
      }
    }
  }
  .sports_banner_sec {
    // background-color: #181818;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    // border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 767.98px) {
      padding: 0rem;
      background-color: transparent;
    }
    .img_box {
      img {
        border-radius: 1.5rem;
      }
    }
  }
  .casino_sec {
    background-color: #182838;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;

    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      align-items: self-start;
      min-width: 20%;
      background: transparent !important;
      padding: 0;
      @media screen and (max-width: 767.98px) {
        margin-bottom: 1rem !important;
      }
      h5 {
        font-weight: 600;
      }
      h6 {
        font-weight: 500;
        font-size: 15px;
      }
      .btn {
        color: var(--whiteClr);
        font-size: 16px;
        // border-color: #1d803a;
        width: 90%;
        min-width: 170px;
        @media screen and (max-width: 1199.98px) {
          width: 100%;
        }
      }
    }
    .games {
      overflow: hidden;
      a {
        img {
          max-width: 95%;
          border-radius: 0.75rem;
        }
      }
    }
  }

  .TopCategories {
    margin: 0 0px 30px;
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0 8px;
      background: var(--bodyClr);
      list-style: none;
      overflow-x: auto;
      overflow-y: hidden;
      height: auto;
      border: 0px;

      box-shadow: 0 1px 3px 0 rgba(226 232 240 / 0.1),
        0 1px 2px -1px rgba(226 232 240 / 0.1);
      .nav-item {
        margin-left: 12px;
        height: auto !important;
        white-space: nowrap;
        padding: 0.375rem;
        &:first-child {
          margin-left: 0;
        }
        button,
        .anav-link {
          background-size: 20px 20px !important;
          background-position: 3px 5px !important;
          background-repeat: no-repeat !important;
          color: var(--whiteClr);
          font-size: 0.85rem;
          font-weight: 600;
          width: auto;
          height: 100%;
          padding: 6px 0 6px 28px;
          border: 0;
          border-radius: 0;
          text-transform: uppercase;
          &.active {
            background: transparent;
            border-bottom: 2px solid #fff;
          }
          &#uncontrolled-tab-example-tab-Roulette {
            background-image: url("https://gbet567.com/images/Roulette_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Baccarat {
            background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Andar\ Bahar {
            background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Poker {
            background-image: url("https://gbet567.com/images/Poker_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Blackjack {
            background-image: url("https://gbet567.com/images/Blackjack_icons.png");
          }
          &#uncontrolled-tab-example-tab-DragonTiger {
            background-image: url("./assets/images/dragon-tiger.png");
          }
          &#uncontrolled-tab-example-tab-TeenPatti {
            background-image: url("https://gbet567.com/images/Teenpatti.png");
          }
          &#uncontrolled-tab-example-tab-32Card {
            background-image: url("https://gbet567.com/images/casino.png");
          }
          &#uncontrolled-tab-example-tab-Seven {
            background-image: url("https://gbet567.com/images/seven.png");
          }
          &#uncontrolled-tab-example-tab-Dices {
            background-image: url("https://gbet567.com/images/dices.png");
          }
          &#uncontrolled-tab-example-tab-Sports {
            background-image: url("../public/images/sportsss.png");
          }
        }
      }
    }
    .casino-games {
      background-color: #040b1b;
      background-color: var(--bodyClr);
      .game-filter {
        align-items: center;
        box-shadow: 0 1px 3px 0 rgba(226, 232, 240, 0.1),
          0 1px 2px -1px rgba(226, 232, 240, 0.1);
        display: flex;
        gap: 10px;
        justify-content: space-between;
        margin: 10px;
        padding: 6px;
      }
      .categories {
        display: flex;
        overflow-x: auto;
        li {
          cursor: pointer;
          margin-right: 10px;
          a {
            align-items: center;
            border-radius: 2px;
            display: flex;
            min-width: -webkit-max-content;
            min-width: max-content;
            padding: 5px 10px;
            &.active {
              border-bottom: 2px solid #fff;
            }
            img {
              height: auto;
              margin-right: 5px;
              width: 20px;
            }
            span {
              color: #fff;
              color: var(--whiteClr);
            }
          }
        }
      }
      .providers .dropdown-toggle {
        background: #1e293b;
        border-color: #1e293b;
        border-radius: 3px;
      }
    }
  }
  .mainBanner {
    @media screen and (min-width: 1320px) {
      padding: 0 20px;
    }
    .slick-slider {
      .slick-prev {
        left: 5px;
        &::before {
          background-image: url(./assets/images/icons/left-arrow.png);
          content: "";
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: center;
          width: 36px;
          height: 36px;
          display: block;
        }
      }
    }
    .slick-next {
      right: 20px;
      &::before {
        background-image: url(./assets/images/icons/right-arrow.png);
        content: "";
        background-repeat: no-repeat;
        background-size: 30px;
        background-position: center;
        width: 36px;
        height: 36px;
        display: block;
      }
    }
    .slick-slide {
      img {
        width: 100%;
        height: auto;
        max-height: calc(100vh - 80px);
        object-fit: contain;
        border-radius: 20px;
      }
      .content {
        bottom: auto;
        font-size: 0.85rem;
        left: 0;
        position: absolute;
        right: auto;
        text-align: left;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        z-index: 99;
        h2 {
          @media screen and (min-width: 768px) {
            font-size: 2rem;
          }
          @media screen and (min-width: 1024px) {
            font-size: 3.5rem;
          }
          @media screen and (max-width: 767px) {
            font-size: 1.15rem;
          }
          span {
            color: var(--primaryClr);
            margin: 0 8px;
          }
        }
        p {
          @media screen and (max-width: 767px) {
            font-size: 0.7rem;
          }
        }
      }
    }
    .slick-dots {
      bottom: 10px;
      text-align: left;
      padding-left: 15px;
      li {
        margin: 0;
        button {
          &::before {
            color: var(--primaryClr);
            font-size: 13px;
          }
        }
      }
    }
  }
  .sectionTitle {
    text-align: center;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    color: var(--textClr);
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2.2px;
    @media only screen and (max-width: 767.98px) {
      font-size: 22px;
    }
    &:after {
      content: " ";
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: var(--primaryClr);
    }
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    margin-top: 20px;
    background: #334155;
    color: #ffffff;
    padding: 2rem;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 767.98px) {
      padding: 1rem;
    }
    .col-6 {
      padding: 0 5px;
    }
    .contact-social {
      margin-left: 150px;
      ul {
        display: flex;
        flex-direction: column;
        li {
          background: #80a4d5;
          margin-bottom: 15px;
          padding: 15px 8px;
          width: 420px;
          border-radius: 5px;
          transition: all 0.3s ease-in;
          &:hover {
            transform: scale(1.1);
            box-shadow: 1px 5px 12px rgba(0, 0, 0, 0.43);
          }
          a {
            display: flex;
            align-items: center;
            color: var(--bodyClr);
            font-size: 16px;
            p {
              margin: 0;
            }
          }
          img {
            width: 60px;
            height: auto;
            margin: 0 15px;
          }
        }
      }
    }
    &.contact-pay-info {
      padding: 90px 1rem 0;
    }
    .contact-img img {
      width: 380px;
      animation-name: bounce-1;
      animation-timing-function: linear;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      border-radius: 25px;
      &.btn-outline-secondary {
        border-color: var(--whiteClr);
        color: var(--whiteClr);
      }
    }
  }
  .account-statement {
    input {
      z-index: 9 !important;
    }
    button.calender-icon {
      svg {
        opacity: 0;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    .search-icon {
      button {
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 40px;
      height: 33px;
      border-color: var(--blackClr);
      border-radius: 0 3px 3px 0;
    }
    h3 {
      border-bottom: 1px solid var(--blackClr);
      font-size: 16px;
      font-weight: 300;
      margin: 12px 0 22px;
      padding: 0 1rem 12px 1rem;
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      border-top: 1px solid var(--blackClr);
      li {
        padding: 22px 1rem;
        border-bottom: 1px solid var(--whiteClr);
        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }
        .details {
          font-size: 12px;
          font-weight: 300;
        }
        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
    table {
      thead {
        background: var(--blackClr);
        tr {
          th {
            color: #fff;
            white-space: nowrap;
          }
        }
      }
      tbody {
        background: #111111;
        color: #fff;
        tr {
          td {
            color: #fff;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .profile-img {
    display: flex;
    align-items: center;
    position: relative;
    img {
      max-width: 140px;
      width: 140px;
      height: 140px;
      border: 2px solid var(--primaryClr);
    }
    span {
      margin-top: 8px;
      font-size: 20px;
      font-weight: 500;
      margin-left: 30px;
    }
    p {
      font-size: 20px;
      margin-left: 30px;
      font-weight: 800;
      margin-bottom: 0;
    }
  }
  .edit-btn {
    background: var(--whiteClr);
    position: relative;
    width: 30px;
    height: 30px;

    fill: var(--whiteClr);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      border: 0;
      &:hover {
        border-radius: 0;
      }
    }
  }
  .profile-info {
    th {
      background: var(--headClr);
      color: var(--whiteClr);
    }
    td {
      background: var(--secondaryClr);
      color: var(--whiteClr);
      white-space: nowrap;
      padding: 10px;
      border-color: #4c535a;
    }
    .edit-btn {
      top: 0;
      right: 0;
      // margin-left: auto;
    }
  }
}

.listings {
  .filter {
    margin-bottom: 20px;

    .dropdown-toggle {
      background: var(--primaryClr) !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }
    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: transparent;
      border-radius: 0 !important;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid var(--whiteClr);
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;
      a {
        border-bottom: 1px solid var(--whiteClr);
        color: var(--headClr);
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;
    svg {
      fill: var(--whiteClr);
    }
  }
}

.steps-canvas {
  .offcanvas-header {
    background: var(--headClr);
    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: var(--whiteClr);
    }
  }
  .form-steps {
    padding: 55px 30px 0;
    .resendOTP {
      margin-right: 15px;
      @media only screen and (max-width: 767px) {
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: var(--whiteClr);
    }
    p {
      font-size: 18px;
      font-weight: 300;
      color: var(--whiteClr);
    }
    input {
      background: var(--headClr);

      border-color: #878787;
      padding: 14px 20px;
      color: var(--whiteClr);
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      fill: var(--whiteClr);
    }
    .btn {
      padding: 9px 30px;
    }

    .form-link {
      font-size: 14px;
      color: var(--lightClr);
      text-decoration: underline;
      &:hover {
        opacity: 0.7;
      }
    }
    .sign-up {
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 16px;
        color: var(--primaryClr);
        text-decoration: underline;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.tabs-area {
  margin-top: 10px;
  ul {
    margin: 0 -1rem;
    flex-wrap: nowrap;
    li {
      width: 100%;
      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;
        &.active {
          background: #f3f3f3 !important;
          color: #26324b !important;
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: var(--whiteClr);
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;
      .top-area {
        background: var(--whiteClr);
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }
      .bottom-area {
        padding: 20px;
        table {
          margin-bottom: 0;
          th {
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          td {
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
          }
        }
      }
    }
    form {
      padding: 55px 30px 0;
      text-align: center;
      input {
        background: var(--headClr);
        border-color: #4a5d6b;
        padding: 14px 20px;
        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;
          &:checked:after {
            content: "";
            background: var(--headClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
      .radio-btns {
        display: flex;
        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
    .card-holder {
      height: 115px;
      background: var(--whiteClr);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;
      img {
        max-height: 40px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--headClr);
      width: 100%;
    }
  }
}

.transfer_info_page {
  .transfer_details {
    background: url("./assets/images/deposit1.jpg") var(--secondaryClr);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 10px;
    overflow: hidden;
    z-index: 9;
    form {
      .form-group {
        background: linear-gradient(to right, #10091d, #000d1b) !important;
        border: 1px solid #013f48;
        border-radius: 5px;
        padding-top: 7px;
        .form-label {
          color: rgba(255, 255, 255, 0.7);
          margin-bottom: 3px;
        }
        .form-control {
          padding: 10px 15px;
          border: 0;
          border-top: 1px solid rgb(1, 63, 72, 0.35);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 1px 2px 0 rgba(0, 0, 0, 0.12),
            inset 0 1px rgba(255, 255, 255, 0.04);
        }
      }
    }
  }
  .deposit_img {
    img {
      animation: moving 3s infinite linear;
      @keyframes moving {
        0% {
          left: 0;
        }
        50% {
          left: 0;
          -webkit-transform: translateY(15px);
          transform: translateY(15px);
        }
        100% {
          left: 0;
        }
      }
    }
  }
}

.upi-list {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 7px;
      width: 50px;
      border: none;
    }
  }
}

.sports_page_banner {
  .slick-slider {
    img {
      border-radius: 5px;
      height: 210px;
      object-fit: cover;
    }
    .slick-dots {
      bottom: 8px;
      li {
        height: 3px;
        button {
          height: 3px;
          padding: 0;
          &:before {
            background: rgb(135 135 135 / 70%);
            color: transparent;
            width: 25px;
            height: 3px;
            opacity: 1;
          }
        }
        &.slick-active {
          button {
            &:before {
              background: var(--primaryClr);
            }
          }
        }
      }
    }
  }
}

.sports_page,
.custom-tabs {
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: transparent;
    // margin-bottom: 15px;
    padding: 0px 10px 15px;
    display: flex;
    justify-content: center;
    .nav-item {
      .nav-link {
        color: #626263;
        font-size: 12px;
        position: relative;
        font-weight: 600;
        text-transform: uppercase;
        padding: 6px 12px;
        border-right: 1px solid #333;
        text-align: right;
        border-radius: 0;
        // background: #fff;
        // box-shadow: -1px 3px 6px 2px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        align-items: center;

        white-space: nowrap;
        img {
          width: 25px;
          height: 25px;
          margin-bottom: 5px;
        }
        p {
          margin: 0;
          top: 2px;
          right: 14px;
          width: 14px;
          height: 14px;
          background: #3e3d3d;
          box-shadow: 2px 5px 15px #1d1d1d;
          border-radius: 50%;
          display: flex;
          position: absolute;
          font-size: 9px;
          justify-content: center;
          align-items: center;
          color: var(--whiteClr);
        }
        &:hover,
        &.active {
          background: transparent;
          color: var(--whiteClr);
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      background: transparent;

      .match-listing-head {
        display: none;
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-radius: 8px;
        // margin-bottom: 10px;
        // height: 25px;
        box-shadow: 0px 4px 3px 0 rgba(0, 0, 0, 0.05);
        position: relative;
        &:nth-child(odd) {
          background-color: #1a1a1a;
        }
        &::before {
          content: "";
          position: absolute;
          background: #1d34a91a;
          position: absolute;
          height: 100%;
          width: 100%;
          right: 200% !important;
          left: -100% !important;
          top: 0;
          bottom: 0;

          opacity: 0;
          -webkit-transition: all 0.4s ease-in-out 0s;
          -moz-transition: all 0.4s ease-in-out 0s;
          transition: all 0.4s ease-in-out 0s;
          border-radius: 8px;
        }
        &:hover::before {
          opacity: 1;
          right: 0 !important;
          left: 0 !important;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 8px 5px;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--whiteClr);
            cursor: pointer;
            .withoutinplay {
              position: relative;
              width: 68px;
              height: 42px;
              border-radius: 5px;
              color: #fff;
              text-align: center;
              font-weight: 600;
              background-color: #424242;
              padding: 6px 1px;
              float: left;
              margin-right: 10px;
              text-transform: uppercase;
            }
            .date_time_info {
              position: relative;
              width: 68px;
              height: 42px;
              border-radius: 5px;
              color: #424242;
              text-align: center;
              font-weight: 600;
              background-color: #424242;
              padding: 6px 1px;
              float: left;
              margin-right: 10px;
              text-transform: uppercase;
              &::after,
              &::before {
                content: "";
                z-index: 0;
                margin: -5%;
                box-shadow: inset 0 0 0 2px;
                border-radius: 7px;
                animation: clipMe 8s linear infinite;
                position: absolute;
                inset: 0;
              }
              &::before {
                animation-delay: -4s !important;
              }
              .date {
                color: #fff;
                font-size: 10px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
              }
              &.in-play {
                background: var(--headClr);
                .date {
                  color: var(--primaryClr) !important;
                }
                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );
                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;
                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }
                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }
                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }
                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;
                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }
                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }
                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }
                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
            p {
              color: var(--whiteClr);
            }
          }
        }
        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          li.suspended {
            position: relative;
            display: flex;
            width: 100%;
            p {
              position: absolute;
              margin: 0;
              color: #b91616;
              -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);
              background: rgba(231 238 255 / 0.8);
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              border-radius: 4px;
            }
          }
          li {
            margin-right: 2px;
            width: 42px;
            height: 45px;
            display: flex;
            align-items: center;
            &:first-child {
              border-radius: 4px 0 0 4px;
              margin-right: 0;
            }
            &:nth-child(2) {
              border-radius: 0 4px 4px 0;
            }
            &:nth-child(3) {
              border-radius: 4px 0 0 4px;
              margin-right: 0;
            }
            :nth-child(4) {
              border-radius: 0 4px 4px 0;
            }
            &:nth-child(5) {
              border-radius: 4px 0 0 4px;
              margin-right: 0;
            }
            &:last-child {
              margin-right: 0;
              border-radius: 0 4px 4px 0;
            }
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 32px;
              line-height: 30px;
              // border-radius: 5px;
              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
                border-radius: 4px 0 0 4px;
              }
              &.no {
                background: var(--oddsLay);
                border-radius: 0 4px 4px 0;
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sports_page {
  background: #101010;
  border-radius: 8px;
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}
.match_details_container {
  background: #242424;
  padding-top: 15px;
  .math_info {
    text-align: center;
    .match_type {
      background: #1a1a1a;

      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;
      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 #1a1a1a;
          box-shadow: 0 0 0 0 #1a1a1a;
        }
        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: #1a1a1a;
        width: 180%;
        height: 2px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          width: 110%;
          left: -5%;
        }
      }
    }
    .team_names {
      margin: 15px 0 10px;
      span {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }
        span {
          color: #aaa;
          font-size: 22px !important;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 1px;
            transform: rotate(17deg);
            height: 37px;
            right: 48%;
            top: -4px;
            background-color: #aaa;
          }
          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        height: auto;
        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }
        img {
          width: 14px;
        }
      }
      .in_play {
        background: var(--whiteClr);
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.07);
        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }
        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }
        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }
        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }
  .sports-match-iframe {
    height: 100%;
    @media screen and (min-width: 1400px) {
      height: 390px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }
    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }
    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }
    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }
    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }
    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }
    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }
    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }
    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }
    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }
    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }
    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}
.page-details {
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    background: #242424;
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      cursor: pointer;
      li {
        background: #1a1a1a;
        color: var(--whiteClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: #242424;
    margin-bottom: 0px;
    padding: 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .date {
      text-align: center;
      color: #fff;
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 15px;
      line-height: 30px;
      color: #fff;
      font-weight: 500;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: #f8f8f8;
      font-size: 12px;
      line-height: 16px;
      color: #242424;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: var(--headClr);
    padding: 5px 0rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }
    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      background-color: #242424;
      color: #ffffff;
    }
    .status-btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.98px) {
        width: 136px;
      }
      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }
  .game-points {
    border: 1px solid var(--whiteClr);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 1px 0 1px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
          margin-right: 5px;
        }
        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            color: #b91616;
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background: rgba(231 238 255 / 0.8);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;

            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              position: absolute;
              top: 7px;
              left: 5px;

              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 24px;
              border-radius: 5px;
            }
          }
          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 8px;
          cursor: pointer;
          border-radius: 4px;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.footer {
  padding: 4rem 1rem 1rem;
  margin-top: 0;
  @media screen and (max-width: 767.98px) {
    padding: 1rem 1rem 6rem;
  }
  .footer-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .footer-right-part {
      order: 2;
    }
    .footer-left-part {
      display: flex;
      gap: 4rem;
      h3 {
        color: #cbd5e1;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      ul li a {
        color: rgba(226 232 240 / 0.7);
        font-size: 14px;
        margin-bottom: 9px;
        display: block;
      }
      .footer-menu-web {
        display: flex;
        gap: 4rem;
      }
    }
  }
  .footer-content {
    padding-top: 1rem;
    margin-top: 2rem;
    border-top: 1px solid var(--blackClr);
    border-bottom: 1px solid var(--blackClr);
    p {
      color: rgb(226 232 240/0.6);
      font-size: 15px;
      margin-bottom: 1rem;
      a {
        color: #e2e8f0;
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }
  .copy-right {
    padding-top: 1rem;

    p {
      font-weight: 600;
      font-size: 15px;
      text-align: center;
      color: rgb(226 232 240/0.5);
      span {
        color: #fff;
      }
    }
  }
  .providers_sec {
    background: var(--blackClr);
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 80%;
      width: 1px;
      background-color: #7e7e7e;
      left: 50%;
      top: 10%;
      transform: translate(-50% - -50%);
    }
    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      text-align: center;
      justify-content: center;
      background-color: transparent !important;
      h5 {
        font-size: 22px;
        font-weight: bold;
      }
      @media screen and (max-width: 1199.98px) {
        margin: 0 auto;
      }
      p {
        span {
          color: var(--primaryClr);
        }
      }
    }
    .providers_logo {
      // width: calc(100% - 300px);
      &.payment_logo {
        a {
          width: 120px !important;
        }
      }
      @media screen and (max-width: 1199.98px) {
        width: calc(100% - 0px);
        margin-bottom: 20px !important;
      }
      .logo_box {
        margin: 3px;
        text-align: center;
        img {
          max-width: 120px;
          padding: 3px;
          &.payment-height {
            max-width: 55px;
          }
        }
      }
    }
  }
  .providers_img {
    text-align: center;
    a {
      display: inline-block;
      margin: 0 10px;
      max-width: 100px;
      &:first-child {
        max-width: 60px;
      }
      img {
        filter: invert(1);
      }
    }
  }
  .helpline {
    background: #222;
    max-width: 300px;
    text-align: center;
    padding: 10px;
    margin: 10px auto;
    border: 1px solid #333;
    border-radius: 10px;
    h3 {
      font-size: 21px;
      font-weight: 700;
    }
    a {
      color: var(--primaryClr);
      font-size: 15px;
      font-weight: 700;
    }
  }
  p {
    color: var(--whiteClr);
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
  .betfair {
    max-width: 110px;
    height: auto;
    filter: invert(1);
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 46px;
        }
      }
    }
  }
  .footer-links {
    ul {
      text-align: center;
      padding: 10px 0 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--blackClr);
      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        border-right: 1px solid #5c6060;
        &:last-child {
          margin-right: 0;
          border-right: 0;
          &::after {
            display: none;
          }
        }
        a {
          color: var(--whiteClr);
          font-size: 14px;
          padding: 0 15px;
          opacity: 0.7;
          &:hover,
          &:focus {
            opacity: 1;
          }
        }
      }
    }
  }
  .social-icons {
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .footer-menu {
    background: var(--bodyClr);
    box-shadow: none;
    border-radius: 0;
    border-top: 2px solid #69738b;
    border-left: 0;
    border-right: 0;
    z-index: 9999999999;
    width: 100%;
    ul {
      li {
        text-transform: uppercase;
        font-size: 10px;
        position: relative;
        flex: 1;
        a {
          padding: 8px 5px 10px;
          color: var(--whiteClr);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;
          cursor: pointer;
          &.homeactive {
            width: 68px;
            justify-content: space-evenly;
            height: 68px;
            position: absolute;
            box-shadow: 0 1px 3px 0 rgba(226, 232, 240, 0.1),
              0 1px 2px -1px rgba(226, 232, 240, 0.1);
            left: 50%;
            animation: glowanimate 1.3s ease-in-out infinite alternate;
            transform: translate(-50%, -50%);
            background: var(--bodyClr);
            border-radius: 50%;
            top: 5px;
          }
          svg,
          img {
            fill: currentColor;
            stroke: currentColor;
            max-width: 22px;
            max-height: 22px;
          }
          span {
            margin-top: 5px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.agent-head.crypto-head {
  background: transparent;
}
.agent-head {
  display: flex;
  flex-wrap: wrap;
  background: var(--headClr);
  border-radius: 6px;
  padding: 10px;
  margin-top: 15px;
  .social-icon {
    width: 4%;
    margin: 0 1%;
    img {
      width: 100%;
    }
  }
}
.live_casino_page {
  scroll-snap-type: mandatory;
  &.live {
    background: var(--headClr);
    color: var(--whiteClr);
  }
  .header-bg {
    .header {
      margin-bottom: 0;
    }
    .headerRight {
      fill: var(--primaryClr);
      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }
  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}
.button-primary.btn.btn-primary {
  padding: 6px 0px !important;
}
.form-control {
  background: transparent !important;
  color: var(--whiteClr) !important;
  &:focus {
    border-color: var(--primaryClr);
  }
}
.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-size: 15px;
    font-weight: 600;
    height: 40px;
    margin-left: 8px;
    padding: 10px 20px;
    border: 0;
    border-radius: 25px;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.betslip-content.inline_betslip {
  .stack-win-value {
    display: flex;
    // margin-bottom: 5px;
    background: #242424 !important;
    padding: 5px 10px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning {
    p {
      line-height: 1.3;
      margin-bottom: 0;
      @media only screen and (max-width: 767.98px) {
        font-size: 0.8rem;
      }
    }
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: end;
      li {
        width: calc(25% - 0.5rem);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        border: 0;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--primaryClr);
          color: var(--blackClr);
          width: 100%;
          padding: 5px;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    justify-content: end;
    > div {
      width: calc(25% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: #0134a9;
        color: var(--whiteClr);
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
        position: relative;
        border: 0 !important;
        z-index: 999;
        &::after {
          transition: all 0.5s ease-out;
          position: absolute;
          content: attr(data-effect);
          backface-visibility: hidden;
          top: 0;
          border-radius: 0.375rem;
          width: 100%;
          left: 0;
          z-index: -1;
          height: 100%;
        }
        &:hover::after {
          right: 0;
          background-color: #7da5ff;
          transition: all ease-in 0.4s;
        }
      }
    }
    .cancel-btn {
      background: var(--lightClr);
      color: var(--siteRedClr);
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
      &:hover {
        background: var(--siteRedClr);
        color: var(--whiteClr);
      }
    }
  }
}

.betslip_popup {
  padding-left: 0 !important;
  .betslip_content {
    border-radius: 4px 4px 0 0;
    .laybet {
      margin-bottom: 15px;
      input {
        border-color: #c6229b !important;
        border-radius: 5px !important;
        background: transparent !important ;
        color: #e15290 !important;
        font-weight: bold !important;
      }
      .stack_field_value,
      .winning {
        color: #e15290 !important;
      }
      .auto-stack {
        background: #242424 !important;
        padding: 10px !important;
        ul li {
          background: transparent !important;
          button.stack-btn {
            background: #101010 !important;
            color: #ffffff !important;
            width: 100% !important;
            padding: 5px !important;
            border-color: #101010 !important;
            &:hover {
              background-color: #e15290 !important;
              color: #fff !important;
            }
          }
        }
      }
    }
    .backbet {
      margin-bottom: 15px;
      input {
        border-color: #0095ff !important;
        border-radius: 5px !important;
        background: transparent !important;
        color: #046dc4 !important;
        font-weight: bold !important;
      }
      .stack_field_value,
      .winning {
        color: #046dc4 !important;
      }
      .auto-stack {
        background: #242424 !important;
        padding: 10px !important;
        ul li {
          background: transparent !important;
          button.stack-btn {
            background: #101010 !important;
            color: #ffffff !important;
            width: 100% !important;
            padding: 5px !important;
            border-color: #101010 !important;
            &:hover {
              background-color: #046dc4 !important;
              color: #fff !important;
            }
          }
        }
      }

      .stack-win-value {
        background: #242424 !important;
      }
    }
    input {
      background-color: #fff !important;
      color: #000 !important;
    }
    .auto-stack {
      ul li {
        width: calc(10% - 0.5rem);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        padding-left: 0;
        background: var(--primaryClr);
        border: 0 !important;
        .stack-btn {
          background: var(--primaryClr);
          color: var(--blackClr);
          width: 100%;
          padding: 5px;
        }
      }
    }
  }

  .modal-body {
    background: var(--headClr);
    border: 1px solid #222;
    border-top-width: 1px;
    border-radius: 4px 4px 0 0;
    padding: 0;
    .table-responsive {
      padding: 0;
    }
    table {
      margin-bottom: 0;
      thead {
        background: #222;
        tr {
          th {
            border: 0;
            color: #fff;
            white-space: nowrap;
          }
        }
      }
      tbody {
        tr {
          border-color: #333;
        }
        td {
          color: #fff;
          white-space: nowrap;
          &.yes {
            color: #72bbef;
          }
          &.no {
            color: #faa8ba;
          }
        }
      }
    }
  }
  .modal-footer {
    background: #444;
    padding: 7px 10px;
    border: 0;
    .btn {
      min-width: 100px;
      margin: 0 auto;
    }
  }
  &.inner_popup {
    .modal-body {
      table {
        thead {
          tr {
            th {
              @media screen and (max-width: 767.98px) {
                &:not(:first-child) {
                  min-width: 80px;
                }
                :last-child {
                  min-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.terms-content {
  margin-top: 20px;
  background: var(--secondaryClr2);
  color: #4e5565;
  padding: 2rem;
  border-radius: 11px;
}
.privay-content {
  margin-top: 20px;
  background: var(--secondaryClr2);
  color: #4e5565;
  padding: 2rem;
  border-radius: 11px;
  p.privacy-points {
    margin-bottom: 9px;
    width: 30%;
    position: relative;
    border-bottom: 1px solid #4e5565;
    padding-left: 12px;
    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #4e5565;
      border-radius: 50%;
      top: 6px;
      left: 0;
    }
  }
}
.responsible-game {
  margin-top: 20px;
  background: var(--secondaryClr2);
  color: #4e5565;
  padding: 2rem;
  border-radius: 11px;
}
.exchange-popup {
  z-index: 99999999999;
  .modal-content {
    background: #1a1e29;
    border-radius: 0 !important;
    .modal-header,
    .modal-body,
    .modal-footer {
      border: 0;
      padding: 15px;
    }
    .btn-close {
      background-image: url(assets/images/close.svg);
      opacity: 1;
    }
    .balnace-wallet {
      display: flex;
      flex-direction: column;
      .balance-value {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        svg {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          color: var(--primaryClr);
        }
      }
    }
    .amount-input {
      form {
        margin-top: 5px;
        input {
          background: #fff !important;
          border-width: 2px;
          border-radius: 5px;
          color: #000 !important;
        }
        .exchange-button {
          display: flex;
          justify-content: space-between;
          button {
            font-size: 16px;
            padding: 6px 8px;
          }
        }
      }
    }
  }
}
input[type="date"].form-control::-webkit-calendar-picker-indicator {
  filter: invert(1);
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.header.beforeheader {
  button.navbar-toggler {
    display: none;
  }
}
.page-details {
  .game-points {
    background: #101010;
    padding-bottom: 0.05rem;
    border-radius: 0;
    border-color: #101010;
    .title {
      background: var(--bodyClr);
      border-bottom: 1px solid #7e97a7;
      span {
        color: var(--bodyClr);
        font-size: 13px;
        font-weight: 600;
      }
    }
    ul {
      li {
        padding: 0 0 0 0.75rem;
        border-color: #242424;
        background: #1a1a1a;
        &:last-child {
          border-bottom: 0;
        }
        .team-name {
          color: #606060 !important;
        }
        .match_odds {
          .info-block {
            &:first-child {
              background: #d8ebf0 !important;
              color: #000 !important;
              span {
                color: #000 !important;
              }
            }
            &:nth-child(2) {
              background: #cdedf4 !important;
              color: #000000 !important;
              span {
                color: #000 !important;
              }
            }

            &:nth-child(5) {
              background: #f9dedf !important;
              color: #000 !important;
              span {
                color: #000 !important;
              }
            }
            &:last-child {
              background: #f5e7e8 !important;
              color: #000 !important;
              span {
                color: #000 !important;
              }
            }
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
    &.bookmaker {
      background: #faf8d8;
      .game-top-area {
        .title {
          background: transparent;
          border: 0;
          span {
            color: #bdc4c7;
            padding: 0;
          }
        }
        .range {
          position: relative;
          left: auto;
          transform: unset;
          background: #bed5d8;
          padding: 5px 10px;
          margin: 4px 0;
          border-radius: 5px;
        }
      }
      ul {
        background: transparent;
        li {
          .match_odds {
            @media only screen and (max-width: 767.98px) {
              width: 140;
            }
            .back_all_odds {
              background: linear-gradient(
                90deg,
                rgba(130, 183, 221, 0.15) 0%,
                rgba(130, 183, 221, 0.8) 65%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  90deg,
                  rgba(151, 199, 234, 0.7) 0%,
                  #97c7ea 65%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(3) {
                  background: #72bbef !important;
                  border: 1px solid #fff !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1),
                  &:nth-child(2) {
                    display: none !important;
                  }
                  &:nth-child(3) {
                    display: block !important;
                    width: 100%;
                  }
                }
              }
              .bookmaker_susp {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                color: var(--whiteClr);
                font-weight: bold;
                text-shadow: 0 0.2666666667vw 1.0666666667vw rgb(0 0 0 / 50%);
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  opacity: 0.8;
                }
              }
            }
            .lay_all_odds {
              background: linear-gradient(
                270deg,
                rgba(231, 170, 184, 0.15) 5%,
                rgba(231, 170, 184, 0.8) 60%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  270deg,
                  rgba(247, 205, 214, 0.75) 5%,
                  #f0c0cb 60%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(1) {
                  background: #faa9ba !important;
                  border: 1px solid #fff !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1) {
                    display: block !important;
                    width: 100%;
                  }
                  &:nth-child(2),
                  &:nth-child(3) {
                    display: none !important;
                  }
                }
              }
            }
            .info-block {
              background: transparent !important;
              margin: 0 !important;
              line-height: 27px;
              span {
                color: #59708d;
              }
              @media only screen and (max-width: 767.98px) {
                &:nth-child(1) {
                  display: block !important;
                }
                &:nth-child(2),
                &:nth-child(3) {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .title {
    &.market_heading {
      background: #242424;
      span {
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 1199.98px) {
  .header_open {
    .navbar {
      transform: translateX(0px) !important;
      .navbar-toggler {
        right: -12px !important;
      }
    }
  }
  .header {
    .navbar {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-230px);
      width: 230px;
      height: 100%;
      background: var(--bodyClr);
      padding: 1rem;
      z-index: 9;
      transition: all 0.3s;
      .navbar-toggler {
        position: absolute;
        top: 70px;
        right: -30px;
        background: var(--whiteClr);
        padding: 0.15rem 0.25rem;
        border-radius: 25px;
        z-index: 99;
        .navbar-toggler-icon {
          width: 20px;
          height: 22px;
        }
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .navbar-collapse {
        position: fixed;
        top: 1rem;
        left: 1rem;
        width: 200px;
        height: 100%;
        display: block !important;
        background: transparent;
        padding: 0;
        margin: 0;
        .navbar-nav {
          width: 100%;
          flex-direction: column;
          a {
            color: var(--whiteClr);
            font-size: 1em;
            text-transform: capitalize;
            width: 100%;
            height: 30px;
            line-height: 1.33;
            margin-bottom: 1.1rem;
            padding: 0;
            &:last-child {
              margin-bottom: 0;
            }
            svg,
            img {
              fill: var(--whiteClr);
              width: 20px;
              height: 20px;
              margin-right: 7px;
            }
            &:hover {
              opacity: 0.8;
            }
            &.logoImg {
              width: fit-content;
              margin-bottom: 2.25rem;
              img {
                width: 150px;
                height: auto;
                @media screen and (max-width: 767.98px) {
                  width: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .hover_Name {
    display: none !important;
  }
  .cards {
    width: 100% !important;
  }
  .agent-head {
    justify-content: space-between;
    .social-icon {
      width: 16%;
      margin: 1%;
    }
  }
  .header {
    .navbar {
      .navbar-toggler {
        border-radius: 0.375rem;
        line-height: 16px;
        padding: 0 0.15rem;
        right: -37px;
        top: 16px;
      }
    }
    &.aftrlgn {
      .logo {
        margin-right: 0;
      }
    }
    .headerRight {
      .menu-btn {
        margin-left: 0;
      }
      .funds {
        ul {
          margin-right: 0px;
          padding: 0px !important;
          li {
            a {
              color: var(--whiteClr);
              display: block;
              border-radius: 22px;
              padding: 3px 10px !important;
              span {
                padding-left: 2px;
              }
            }
          }
          li:after {
            display: none;
          }
        }
      }
    }
  }
  .custom-tabs {
    padding-top: 0px;
    .sports-name-listing {
      ul {
        padding: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .page-details {
    .game-top-area {
      .range {
        display: none;
      }
      .date {
        display: none;
      }
    }
    .game-points {
      ul {
        li {
          display: flex;
          padding-left: 0.5rem;
          // border-bottom: 1px solid #fff;
          .team-name {
            width: calc(100% - 150px);
            flex-direction: revert;
            justify-content: space-between;
            color: var(--bodyClr);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 0.25rem 0 0;
            &.othername {
              display: none;
            }
          }
          .status-btns {
            width: 150px;
            display: flex;
            align-items: center;
            .info-block {
              width: inherit;
              display: none;
              &:nth-child(3),
              &:nth-child(4) {
                display: flex;
                width: 50%;
              }
              &:nth-child(4) {
                border: 0;
              }
              &:first-child {
                margin-left: 0;
                display: none;
              }
            }
            .info-block.no:nth-child(2) {
              display: flex;
              width: 50%;
            }
          }
        }
      }
    }
  }
  .sports_page,
  .custom-tabs {
    .tab-pane {
      .game-listing {
        .match-info {
          display: block;

          padding: 4px 10px;

          .match-name {
            width: 100%;
          }
          .live-match {
            height: 10px;
            width: 10px;
            margin-right: 8px;
            background: #c4cfd6;
            border: 1px solid #366601;
            border-radius: 50%;
          }
          .listing {
            padding-bottom: 5px;
            li {
              width: 100%;
              margin-right: 3px;
              &:nth-child(2),
              &:nth-child(4) {
                margin-right: 6px;
              }
            }
          }
        }
        .match-listing-head {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header {
    .headerRight {
      .funds {
        ul {
          li {
            a {
              display: flex;
              border-radius: 22px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.mybets-tabs-section {
  ul {
    width: max-content;
    display: flex;
    border: 1px solid var(--blackClr);
    border-top: 1px solid var(--blackClr) !important;
    margin-bottom: 10px !important;
    @media only screen and (max-width: 767px) {
      overflow-x: scroll;
      width: auto;
    }
    li {
      padding: 8px 15px !important;
      border-bottom: 0 !important;
      border-right: 1px solid var(--blackClr);
      white-space: nowrap;
      &:hover,
      &.active {
        background: var(--headClr);
      }
      div {
        color: var(--whiteClr);
      }
    }
  }
}

.account-statement {
  table {
    tbody {
      tr {
        td {
          span.lay {
            background: #faa8ba;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
        td {
          span.back {
            background-color: #72bbef;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
      }
    }
  }
}
.transfer_info_page {
  input {
    border-color: #585858 !important;
    &::placeholder {
      color: #fff;
      font-weight: bold;
    }
  }
  button[type="submit"] {
    margin-top: 15px;
  }
  .upi-list {
    li {
      border: 0 !important;
      margin-top: 20px;
    }
  }
}
.panel {
  svg {
    fill: white;
  }
}

.whtasapp-all-page {
  position: fixed;
  right: 10px;
  z-index: 9;
  bottom: 125px !important;
  width: 135px;
  height: 40px;
}
.confirm-title {
  color: black;
}
.edit-btn {
  svg {
    fill: #000;
    font-size: 15px;
  }
}

.bookmaker {
  background: #1a1a1a !important;
  span {
    background-color: transparent !important;
    margin-left: 0 !important;
  }
  .status-btns {
    height: 44px;
    width: 408px;
    margin-bottom: 2px;
  }
  .points-status {
    width: 100%;
    top: 0px !important;
    width: 100% !important;
    height: 45px !important;
    margin: 0 !important;
    font-weight: bold;
    &::after {
      height: 29px !important;
      top: 5px !important;
    }
  }
}

@-webkit-keyframes sports-book-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50%,
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes sports-book-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50%,
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes sports-book-bouncing {
  0% {
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  20% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  32.5% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  41% {
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  46.5% {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5%,
  27.5%,
  37.5%,
  43.5% {
    -webkit-transform: translateY(0) scale(1.1, 0.9);
    transform: translateY(0) scale(1.1, 0.9);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes sports-book-bouncing {
  0% {
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  20% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  32.5% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  41% {
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  46.5% {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5%,
  27.5%,
  37.5%,
  43.5% {
    -webkit-transform: translateY(0) scale(1.1, 0.9);
    transform: translateY(0) scale(1.1, 0.9);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.sessionmarket .game-points ul li .status-btns .info-block:nth-child(2) {
  display: flex !important;
  width: 68px !important;
}
.webslider {
  display: flex;
  .imgBox {
    margin: 0 8px;
    width: 33.33%;
  }
}

.bet-liability-markets {
  border-radius: 5px;
  margin: 4px 0px;
  background-color: #f8f8f8;
  color: #1a1a1a;
  padding: 0px 5px;
}

.animate-img {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  .spin-anim {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    .img-spinbg {
      position: absolute;
      left: 50%;
      width: 1.9375rem !important;
      height: 1.9375rem !important;
      z-index: 1;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translate(-50%);
      top: 6%;
      opacity: 0;
    }
    .img-turntable {
      -webkit-animation: rotate-e1vxjz6v 2s infinite linear;
      animation: rotate-e1vxjz6v 2s infinite linear;
      width: 24px !important;
      height: 24px !important;
      z-index: 2;
    }
    .img-pointer {
      position: absolute;
      top: -6px;
      left: 40%;
      transform: translate(-50%);
      width: 15px !important;
      height: auto !important;
      z-index: 3;
      margin-right: 0 !important;
    }
  }
}

@-webkit-keyframes rotate-e1vxjz6v {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-e1vxjz6v {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes game-category__icon-floating {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes game-category__icon-floating {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@-webkit-keyframes game-category__icon-floating {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes game-category__icon-floating {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes loginbtnpopup {
  0% {
    box-shadow: 0 0 0 0 rgba(29, 128, 58, 0.7);
    transform: scale(0.95);
  }

  70% {
    box-shadow: 0 0 0 10px transparent;
    transform: scale(1);
  }

  to {
    box-shadow: 0 0 0 0 transparent;
    transform: scale(0.95);
  }
}
.sign-in-btn {
  animation: loginbtnpopup 2s infinite;
  box-shadow: 0 0 0 0 #1d803a;
  color: #fcfcfc;
  font-size: 22px;
  font-weight: 800;
  line-height: 24px;
  transform: scale(1);
}
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
.payment-info.social {
  background-color: transparent !important;
  position: fixed;
  right: 0;
  top: 40%;
  width: 70px;
  margin: -30px -1rem;
  z-index: 99;
  ul {
    flex-direction: column;
    position: relative;
    li {
      margin-bottom: 10px;
      a {
        align-items: center;
        background-color: rgb(0 0 0 / 56%);
        border: 2px solid #4a4a4a;
        border-radius: 5px;
        display: flex;
        height: 60px;
        position: relative;
        right: 15px;
        transition: all 0.3s;
        width: 70px;
        cursor: pointer;
        &:hover {
          right: 250px;
          width: 300px;
        }
        img {
          height: auto;
          margin: 0 15px;
          width: 35px;
        }
        p {
          color: #fff;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }
}
.Live-Casino {
  a {
    border-radius: 8px;
  }
}
.heading {
  display: flex;
  justify-content: space-between;
  background: var(--secondaryClr1);
  align-items: center;
  padding: 5px 7px;
  h4 {
    margin: 0;
  }
}
.cards {
  span {
    color: orange;
    margin-left: 4px;
  }
  border: 1px solid #e56931;
  padding: 15px;
  border-radius: 10px;

  width: 32.33%;
  margin: 0.5%;
}
.amount_data {
  justify-content: space-between;
}
.date_bonus {
  justify-content: space-between;
}
.bonus {
  h6 {
    margin-top: 10px;
    font-weight: 600;
    color: #e56931;
  }
  border-top: 1px solid gray;
  margin-top: 10px;
  border-bottom: 1px solid gray;
}
.comments {
  .accordion-header {
    button {
      font-weight: 600;
      background: #031c2a;
      color: white;
      &::after {
        filter: invert(1);
      }
    }
  }
  .accordion-item {
    border: none;
  }
  margin-top: 10px;
}
.cancel_bonus {
  background: red;
  padding: 1px 2px;
  font-size: smaller;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
}
.reqDate {
  font-weight: 700;
  margin-bottom: 10px;
}
.date_new {
  font-weight: normal;
  font-size: 12px;
}
.amount_data {
  .status {
    color: green;
    text-transform: uppercase;
  }
  .status_claimed {
    color: #f1c232;
    text-transform: uppercase;
  }
  .staus_pending {
    color: #f1c232;
    text-transform: uppercase;
  }
  .staus_expired {
    color: #cc0000;
    text-transform: uppercase;
  }
  .staus_canceled {
    color: #cc0000;
    text-transform: uppercase;
  }
}
.bonus_table {
  color: white;
  // border: 2px solid #353c4b;
  th {
    white-space: nowrap;
    padding-inline: 10px;
  }
  tr {
    background: #16394c;
  }
}
.main_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .progress {
    background: #393939;
  }
}
.casino_sec .slick-initialized .slick-slide {
  width: 16.6% !important;
}
.casino_sec .slick-track {
  display: flex;
}
.new_table {
  thead {
    th {
      padding: 5px;
    }
    background: #031c2a;
  }
  background: #353c4b;
  padding: 6px;
}
.primary_card {
  border-radius: 10px;
  border: 1px solid #d58b2f;
  .stats-card {
    display: flex;
    ul {
      border-right: 1px solid #353c4b;
      width: 50%;
      &:last-child {
        border: 0;
      }
      li {
        // height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #353c4b;
        font-weight: 700;
        span {
          width: 50%;
          // font-size: 13px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}
.date_Bonus {
  font-size: 11px;
}
// .primaryCard_td {
//   background: #353c4b;
// }

.progressbar-section {
  .progress-bar {
    min-width: 5% !important;
  }
}
.referral_link {
  display: flex;
  justify-content: space-between;
  background: #475572;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  p {
    margin-top: 10px;
    span {
      color: #b7b7b7;
      margin-right: 20px;
      font-size: 15px;
      font-weight: 700;
    }
  }
}
.referrals_cards {
  background: #475572;
  border-radius: 5px;
  padding: 10px;
  width: 48%;
}
.referr_user {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  p {
    font-size: x-large;
  }
  svg {
    font-size: large;
    margin-top: 8px;
  }
}
.referral_card_main {
  display: flex;
  justify-content: normal !important;
  margin-top: 10px !important;
  gap: 15px;
  .referrals_cards {
    margin-top: 10px;
    width: 100%;
    background: var(--secondaryClr1);
    border-radius: 5px;
    flex: 1 1;
    h5 {
      font-size: 1rem;
    }
    .referr_user {
      display: flex;
      gap: 10px;
      svg {
        fill: var(--primaryClr);
      }
      p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
.referr_tabs {
  ul {
    gap: 20px;
    border-bottom: 0;
    li {
      button {
        background: #182c54 !important;
        color: #fff;
        border: 0;
        border-radius: 5px 5px 0 0;
        padding: 8px 35px;
        &.active {
          background: #44516d !important;
          color: #fff !important;
          border: none;
          padding: 8px 35px;
        }
        &:hover {
          background: none;
          border: none;
          color: #fff;
        }
      }
    }
  }
}
.referr_table {
  color: #fff;
  thead {
    background: #44516d;
  }
  tbody {
    tr {
      background: #727d95;
    }
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    color: #fff !important;
    &:hover {
      background: none;
    }
  }
  .dropdown-menu {
    margin-top: 10px;
    background: #727d95;
  }
  .dropdown {
    button {
      background: #727d95;
      border: 0;
      &:hover {
        background: none;
      }
    }
  }
}

.all-images ul {
  display: flex;
  flex-wrap: wrap;
  background: transparent !important;
  gap: 15px;
}
.all-images ul li {
  width: calc(25% - 11.25px);
  height: auto !important;
  flex: inherit !important;
  position: relative;
  border-radius: 8px;
  background-color: #1e293b;
  @media only screen and (max-width: 767.98px) {
    width: calc(50% - 7.5px);
  }
}
.all-images li a {
  border-radius: 8px;
  font-weight: 300;
  transition: 0.4s;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.all-images li a img {
  width: 100%;
  height: 201px;
  border-radius: 8px 8px 0 0;
}
.all-images li a ~ .focus-border::after,
.all-images li a ~ .focus-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #f8fcff;
  transition: 0.3s;
}
.main .TopCategories .tab-content {
  padding: 1rem;

  .Live-Casino {
    .container-fluid {
      padding: 0 !important;
    }
  }
}
.all-images li a ~ .focus-border::after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.all-images li a ~ .focus-border i::after,
.all-images li a ~ .focus-border i::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #f8fcff;
  transition: 0.4s;
}
.all-images li a ~ .focus-border i::after {
  left: auto;
  right: 16px;
  top: auto;
  bottom: 11px;
}

.all-images li a:hover ~ .focus-border i::after,
.all-images li a:hover ~ .focus-border i::before {
  height: 91%;
  transition: 0.4s;
}
.all-images li a:hover ~ .focus-border::after {
  width: 90% !important;
}
.all-images li a:hover ~ .focus-border i::before {
  top: 10px;
  left: 13px;
}
.all-images li a:hover ~ .focus-border::before {
  top: 10px;
  left: 14px;
}
.all-images li a:hover ~ .focus-border::after {
  bottom: 10px;
  right: 16px;
}
.all-images li a:hover ~ .focus-border::after,
.all-images li a:hover ~ .focus-border::before {
  width: 90%;
  transition: 0.3s;
}
.all-images li a:hover img {
  -webkit-filter: blur(4px); /* Chrome, Safari, Opera */
  filter: blur(4px);
}
.all-images li a .play_button {
  display: none;
}
.all-images li a:hover .play_button {
  filter: none;
  display: block;
  position: absolute;
  top: 20%;
  width: 20%;
  height: auto;
  left: 40%;
}
.all-images li a:hover .hover_Name {
  font-size: 28px !important;
  font-weight: 900;
  line-height: 30px;
  width: 90%;
}
.all-images ul li a p {
  font-size: 0.875rem;
  width: 90%;
  color: rgb(226 232 240 / 0.5);
  text-align: center;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

@keyframes glowanimate {
  0% {
    box-shadow: 0 0 1px #e2eafc, 0 0 2px#cdb5e1, 0 0 3px #0f172a,
      0 0 4px #0f172a, 0 0 5px #0f172a;
  }

  to {
    box-shadow: 0 0 5px #e2eafc, 0 0 7px #cdb5e1, 0 0 9px #0f172a,
      0 0 11px #0f172a, 0 0 13px #0f172a;
  }
}

.matchlistpage {
  background-color: #eeeeee;
}

.login-page {
  max-width: 40rem !important;
  margin: 0 auto;
  padding: 3.5rem 3rem;
  @media only screen and (max-width: 767.98px) {
    padding: 1rem !important;
  }
  .heading1 {
    text-align: center;
    span {
      font-size: 1.875rem;
      line-height: 2.25rem;
      color: var(--textClr);
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.05em;
    }
  }
  .main-login-form {
    background-color: var(--secondaryClr2);
    border-radius: 8px;
    padding: 2.25rem 2.5rem;
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    margin-top: 18px;
    @media only screen and (max-width: 767.98px) {
      max-height: calc(100vh - 150px);
    }
    .CountryImg {
      position: absolute;
      z-index: 9;
      top: 36px;
      display: flex;
      left: 12px;
      align-items: center;
      img {
        width: 100% !important;
        margin-right: 8px;
      }
      span {
        color: var(--bodyClr) !important;
        font-weight: 600;
      }
    }
    .mobile-dropdowninput input {
      padding-left: 70px !important;
    }
    form {
      label {
        font-size: 15px;
        color: var(--bodyClr);
        margin-bottom: 6px;
        font-weight: 600;
      }
      input {
        font-size: 14px;
        font-weight: 500;
        box-shadow: none !important;
        height: 40px;
        border-radius: 8px;
        background-color: #cbd5e1 !important;
        border: 1px solid #cbd5e1 !important;
        color: var(--bodyClr) !important;
        margin-bottom: 28px;
        &:hover {
          border-color: var(--bodyClr) !important;
          background-color: #cbd5e1 !important;
          box-shadow: none !important;
        }
        &:focus {
          border-color: var(--bodyClr) !important;
          background-color: #cbd5e1 !important;
          box-shadow: none !important;
        }
      }
      .icon {
        position: absolute;
        top: 48px;
        width: 40px;
        display: flex;
        right: 2px;
        transform: translate(0, -50%);
        height: 20px;
        border-radius: 0 8px 8px 0;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
        }

        svg {
          fill: var(--blackClr);
        }
      }
    }
    .password-section {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      a {
        color: var(--bodyClr);
        text-decoration: underline;
        font-weight: 600;
      }
      button {
        background: #0f172a !important;
        height: 37px;
        padding: 0 35px;
        font-size: 16px;
        line-height: 1.25rem !important;
        font-weight: 400;
        margin: 0;
        animation: inherit;
        transition: transform 0.2s;
        position: relative;
        &:hover {
          transform: scale(1.2);
          border: 0;
        }
        img {
          position: absolute;
          width: 15px;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .sign-up {
      border-top: 1px solid rgba(15 23 42 / 0.2);
      padding-top: 15px;
      display: flex;
      justify-content: center;
      color: var(--bodyClr);
      a {
        font-weight: bold;
        color: var(--bodyClr);
        margin-left: 5px;
        text-decoration: underline;
      }
    }
  }
}

.offcanvas-backdrop.show {
  display: none;
}
.hover_Name {
  position: absolute;
  top: 68%;
  left: 50%;
  color: white !important;
  font-size: 24px !important;
  font-family: "Raleway" !important;
  text-transform: uppercase;
  line-height: 24px;
  text-shadow: 0px 2px 2px rgb(32 32 32 / 86%);
  transform: translate(-50%, -50%);
}

.suspendedPara {
  position: relative;
  p {
    position: absolute;
    color: #b91616;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: rgba(231 238 255 / 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    margin: 0;
  }
}

.register {
  text-align: center;
  form {
    text-align: left;
  }
  button {
    background: #0f172a !important;
    height: 37px;
    padding: 0;
    width: 107px;
    font-size: 16px;
    line-height: 1.25rem !important;
    font-weight: 400;
    animation: inherit;
    transition: transform 0.2s;
    margin: 0 auto;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.register button {
  background: #0f172a !important;
  height: 37px;
  padding: 0;
  width: 107px;
  font-size: 16px;
  line-height: 1.25rem !important;
  font-weight: 400;
  animation: inherit;
  transition: transform 0.2s;
  margin: 0 auto;
}

.AccountPage {
  max-width: 64rem !important;
  padding: 1.5rem 3rem;
  form {
    display: flex;
    flex-wrap: wrap;
    .register-field {
      width: 49%;
      margin: 0.5% 0.5% 18px !important;
      input {
        margin-bottom: 0;
      }
    }
  }
}

.slots-section {
  margin: 25px auto 0;
  width: 95%;
  .game-section {
    p {
      margin: 0;
      background-color: var(--headClr);
      color: var(--whiteClr);
      font-size: 15px;
      padding: 8px;
      border-radius: 0 0 8px 8px;
      white-space: nowrap;
    }
  }
  .game-heading {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    img {
      margin-right: 10px;
      width: 30px;
    }
    span {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 0;
    li {
      background-color: transparent;
      display: block;
      height: auto;
      margin: 0.5%;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 19%;
      &:hover .casino_overlay {
        inset: 0 0 0 0;
        img {
          height: 45px !important;
          width: 45px !important;
        }
      }
      img {
        border-radius: 8px 8px 0 0;
        width: 100% !important;
      }
      .casino_overlay {
        align-items: center;
        background: linear-gradient(
          0deg,
          rgba(37, 35, 152, 0.49),
          rgba(37, 35, 152, 0.49)
        );
        border-radius: 8px;
        content: "";
        display: flex;
        inset: 0 0 0 100%;
        justify-content: center;
        position: absolute;
        transition: all 0.9s;
        img {
          border-radius: 8px 8px 0 0;
          height: 0;
          width: 0 !important;
        }
      }
    }
  }
}

@keyframes clipMe {
  0%,
  to {
    clip: rect(0, 75px, 2px, 0);
  }

  25% {
    clip: rect(0, 2px, 75px, 0);
  }

  50% {
    clip: rect(45px, 75px, 75px, 0);
  }

  75% {
    clip: rect(0, 75px, 75px, 45px);
  }
}
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
.loader-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  img {
    width: 220px;
  }
}

.footer-content {
  p {
    font-size: 18px;
    font-weight: 600;
  }
  ul {
    padding-left: 35px;
    li {
      margin-bottom: 12px;
      font-size: 15px;
    }
  }
  h4 {
    font-size: 22px;
    font-weight: 600;
    margin: 18px 0;
  }
}

.cashloader {
  position: relative;
  width: 221px;
  height: 28px;
  @media only screen and (max-width: 767.98px) {
    width: 180px !important;
    height: 33px !important;
  }
  @media only screen and (max-width: 575.98px) {
    width: 140px !important;
    height: 33px !important;
  }
  p {
    position: absolute;
    background: #252c3d;
    border-radius: 25px;
    padding: 5px;
    left: 0;
    top: 0;
    color: #fff;
    width: 100%;
    height: 28px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 767.98px) {
      height: 33px !important;
      font-size: 11px;
      border-radius: 25px 0 0 25px !important;
    }
    img {
      width: 30px;
      @media only screen and (max-width: 575.98px) {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .WithdrawPageSection .WithDrawSection.tabs-area ul {
    margin: 0 -1rem !important;
  }
  .menuDropdown {
    height: 52% !important;
    width: 70% !important;
    left: 50% !important;
    top: 62% !important;
    transform: translate(-50%, -50%) !important;
    .offcanvas-header {
      display: none;
    }
    .offcanvas-body {
      background: #0c1427;
      padding-bottom: 0;
      .offcanvas-head {
        display: none;
      }
      .menu-items {
        border-top: 0;
        margin: 0;
        li {
          border-color: #1e293b;
          a {
            display: flex;
            align-items: center;
            padding: 17px 1rem;
            font-size: 14px;
            color: rgb(255 255 255);
            font-weight: 600;
            margin: 0 0 8px;
            background: #1e293b;
            border-radius: 8px;
            img {
              width: 20px;
              height: auto;
              filter: invert(1);
            }
          }
        }
      }
    }
    .offcanv-footer {
      display: none;
    }
    .social_links {
      display: none !important;
    }
  }
  .headerMainDropdown {
    .dropdown-menu {
      background: #0c1427;
      a {
        color: #fff;
        font-size: 14px;
      }
      button {
        width: 150px;
        color: #fff !important;
        text-align: left;
        font-size: 14px;
      }
    }
    .dropdown-item {
      display: block !important;
      background: #1e293b;
      color: #fff !important;
      margin: 5px;
      border-radius: 5px;
      padding: 10px !important;
      &:nth-child(3) {
        background: transparent !important;
        display: inline-block !important;
        width: 45% !important;
        margin: 15px 5px 0;
        padding: 0 !important;

        button {
          height: auto !important;
          // color: red !important;
          font-weight: 700;
          background: red !important;
          color: #fff !important;
          padding: 12px !important;
          width: 100% !important;
          text-align: center !important;
        }
      }
      &:last-child {
        background: transparent !important;
        display: inline-block !important;
        width: 45% !important;
        margin: 15px 5px 0;
        padding: 0 !important;

        button {
          height: auto !important;
          // color: green !important;
          font-weight: 700;
          background: green !important;
          color: #fff !important;
          padding: 12px !important;
          width: 100% !important;
          text-align: center !important;
        }
      }
    }
  }
}

.menuDropdown {
  .offcanvas-head {
    .btn-secondary {
      background: red !important;
      color: #fff !important;
      border: 0;
      font-size: 16px;
    }
    .btn-primary {
      background: green !important;
      color: #fff !important;
      border: 0 !important;
      font-size: 16px;
    }
  }
}

.walletSection {
  .wallet-leftpart,
  .deposit_img {
    width: 50%;
  }
  .deposit_img {
    display: flex;
    justify-content: center;
    img {
      width: 60% !important;
      animation: moving 3s infinite linear;
      @keyframes moving {
        0% {
          left: 0;
        }
        50% {
          left: 0;
          -webkit-transform: translateY(15px);
          transform: translateY(15px);
        }
        100% {
          left: 0;
        }
      }
    }
  }
  .transfer_details {
    background: #334155;
    border-radius: 8px;
    h5 {
      background: #1d232d;
      padding: 15px;
      font-size: 25px;
      border-radius: 12px 12px 0 0;
    }
    .wallet-details {
      border-radius: 0 0 12px 12px;
      ul {
        display: flex;
        flex-direction: column;
        li {
          display: flex;
          width: 100% !important;
          justify-content: center;
          margin-bottom: 15px;
          h6 {
            width: 50%;
          }
          a.withdraw {
            background: red !important;
            color: #fff !important;
            border: 0;
            font-size: 16px;
            padding: 8px;
            margin-left: 18px;
            border-radius: 8px;
          }
          a.deposit {
            background: green !important;
            color: #fff !important;
            border: 0;
            font-size: 16px;
            padding: 8px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

.changePasswordModal {
  .modal-header {
    background-color: var(--headClr);
    border-color: #333;
    border-radius: 4px 4px 0 0;
    .btn-close {
      filter: invert(1);
    }
  }
  .modal-body,
  .modal-footer {
    background-color: var(--bodyClr);
    border-color: #333;
    span.icon_view {
      position: absolute;
      top: 19%;
      right: 9px;
      svg {
        path {
          fill: #fff;
        }
      }
      img {
        filter: invert(1);
      }
    }
  }
  .modal-footer {
    border-radius: 0 0 4px 4px;
  }
}
.cancel_bonus_modal {
  .modal-dialog {
    .modal-content {
      background: #fff;
      color: #000;
    }
  }
}
.eye_ {
  fill: yellow;
}
.modal-backdrop.show {
  opacity: 0.8 !important;
}
.referral_link {
  background-color: var(--headClr);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 20px;
  h5 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 20px;
  }
  div {
    background: #44516d;
    border-radius: 5px;
    color: #e7e4e4;
    flex: 1 1;
    padding: 8px 15px;
  }
}

.static-tabs-section {
  ul {
    background: #101010;
    border: 0;
    border-radius: 8px;
    height: 48px;
    display: none;
    li {
      flex: 1;
      height: 48px;
      button {
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: #101010;
        color: var(--whiteClr);
        border: 0;
        border-radius: 0;
        &.active,
        &:focus,
        &:hover {
          background: rgb(6, 6, 6);
          background: linear-gradient(
            51deg,
            rgba(6, 6, 6, 1) 0%,
            rgba(54, 54, 54, 1) 100%
          );
          border: 0;
          border-radius: 8px;
          color: var(--whiteClr) !important;
        }
        span {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          img {
            width: 30px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.gatewaySection {
  max-width: 800px;
  margin: 40px auto 15px;
  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;
    li {
      width: 50%;
      button {
        background: var(--blackClr);
        border-radius: 15px 15px 0px 0px;
        border: 0;
        width: 100%;
        color: #fff;
        &.active {
          background: linear-gradient(
            180deg,
            var(--secondaryClr1) 30%,
            var(--blackClr) 100%
          );
          border-radius: 15px 15px 0px 0px;
          color: #fff;
        }
      }
    }
  }
  .tab-content {
    background: var(--secondaryClr);
    padding: 20px 50px;
    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
      .cards1 {
        cursor: pointer;
        background: linear-gradient(0deg, #031c2a 0%, #213040 100%);
        border: 0.5px solid #414141;
        box-sizing: border-box;
        border-radius: 10px;
        width: 31.3333%;
        float: left;
        margin: 1% 1%;
        .card-icon {
          padding: 12px 5px 4px 5px;
          font-size: 12px;
          border-bottom: 0.5px solid #414141;
          text-align: center;
          img {
            width: 80px;
          }
        }
        p {
          padding: 11px 4px;
          font-size: 14px;
          font-size: 12px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
.gatewayForm {
  margin: 25px 0;
  @media only screen and (max-width: 767.98px) {
    margin: 15px 0;
  }
  input {
    border-color: #4a5d6b;
    padding: 14px 20px;
  }
  button {
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 120px;
    padding: 9px 30px;
    text-transform: uppercase;
  }
}
.newImages {
  ul {
    li {
      width: 32%;
    }
  }
}
.searchGames1 {
  background: transparent;
  .modal-dialog {
    .modal-content {
      color: #000;
      border: 2px solid var(--headClr);
      .modal-header {
        background: var(--headClr);
        border-bottom: 0;
        border-radius: 4px 4px 0 0;
        .modal-title {
          color: var(--whiteClr);
          @media only screen and (max-width: 767.98px) {
            font-size: 16px;
          }
        }
        .btn-close {
          color: goldenrod;
          filter: invert(1);
        }
      }
      .modal-body {
        background: #000;
        color: var(--whiteClr) !important;
        border-radius: 0 0 4px 4px;
        input {
          color: var(--whiteClr) !important;
          border-color: var(--blackClr);
          border-radius: 3px;
        }
      }
    }
  }
}
.GamesShow {
  display: flex;
  flex-wrap: wrap;
  gap: 6%;
  justify-content: center;
  align-items: center;
  .displayGames {
    width: 40%;
    margin-bottom: 30px;
    box-shadow: 0px 3px 10px 10px #334155;
  }
}
.searchGames {
  display: flex;
  background: var(--secondaryClr1);
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 5px;
  color: #fff !important;
  padding: 5px 15px;
  cursor: pointer;
  @media only screen and (max-width: 1199.98px) {
    background: transparent !important;
    padding: 5px;
  }
  svg {
    @media only screen and (max-width: 1199.98px) {
      width: 20px;
      height: 20px;
    }
  }
  p {
    margin-left: 10px;
    margin-bottom: 0;
    @media only screen and (max-width: 1199.98px) {
      display: none;
    }
  }
}

.WithdrawPageSection {
  .WithDrawSection.tabs-area {
    width: 760px;
    ul {
      border: 0;
      background: #2c2f3d;
      margin: 0;
      li {
        button {
          color: var(--whiteClr) !important;
          border-radius: 0 !important;
          text-align: center;
          font-weight: 400;
          background: var(--blackClr);
          padding: 10px !important;
          &.active {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              180deg,
              var(--secondaryClr1) 30%,
              var(--blackClr) 100%
            ) !important;
          }
        }
      }
    }
    .tab-content {
      padding-bottom: 0 !important;

      .WithDrawBankDetails {
        background-color: #021622;
        background-color: #021622;
        background: url("./assets/images/banner/withdrawbackground.jpg") #021622;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10px;
        .heading {
          display: none;
        }
        .playerBankDetails {
          input,
          select {
            height: 45px;
            border-radius: 3px !important;
          }
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          button.btn-primary {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgba(6, 99, 177, 1) 0%,
              rgba(40, 131, 239, 1) 100%
            );
            border-radius: 5px !important;
            border: 0;
            font-weight: 400;
            height: 45px;
          }
        }
        h4 {
          font-size: 18px;
          margin-bottom: 15px;
        }
        .WithdrawTable {
          thead {
            border: 1px solid #7f898f;
            border-radius: 3px !important;
            th {
              white-space: nowrap;
            }
          }
          tbody {
            tr {
              td {
                color: var(--whiteClr) !important;
                padding: 12px 0 !important;
                line-height: 30px;
                input[type="checkbox"] {
                  display: none;
                }
                label {
                  display: block;
                  margin: 0.2em;
                  cursor: pointer;
                  padding: 0.2em;
                }
                button {
                  margin-right: 8px;
                }
              }
              th,
              td {
                color: var(--whiteClr) !important;
                padding: 12px !important;
                line-height: 30px;
                background: transparent !important;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .WithdrawRequestSection {
        ul.nav-tabs {
          margin-top: 10px;
          background-color: var(--whiteClr) !important;
          button {
            background: var(--whiteClr) !important;
            color: var(--blackClr) !important;
            text-align: center !important;
            &.active {
              background: #169f00 !important;
              color: var(--whiteClr) !important;
            }
          }
        }
        .tab-content {
          background: url("./assets/images/banner/withdrawbackground.jpg")
            #021622;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 10px;
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
        }

        .WithdrawRequestBankSection {
          background-color: #021622;
          padding: 10px;
          .WithdrawTable {
            border: 0;
            thead {
              background-color: #2c2f3d;
              border: 0;
              border-radius: 3px !important;
              th {
                border: 0;
                color: var(--whiteClr);
                white-space: nowrap;
                background: transparent;
              }
            }
            tbody {
              tr {
                background: transparent;
                th {
                  background: transparent;
                  line-height: 40px;
                  color: var(--whiteClr);
                }
                td {
                  color: var(--whiteClr) !important;
                  padding: 12px 0.5rem !important;
                  line-height: 30px;
                  background: transparent;
                  button {
                    margin-right: 8px !important;
                  }
                }
              }
            }
          }
        }
        .sectionTitle {
          color: var(--whiteClr) !important;
          margin: 18px 0 25px !important;
          position: relative;
          text-align: center !important;
          &::after {
            position: absolute;
            content: "";
            border: 1px solid var(--whiteClr);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            bottom: 0;
          }
        }
        button.btn-primary {
          background: rgb(6, 99, 177);
          background: linear-gradient(
            100deg,
            rgba(6, 99, 177, 1) 0%,
            rgba(40, 131, 239, 1) 100%
          );
          border-radius: 5px !important;
          border: 0;
          font-weight: 400;
          height: 45px;
        }
      }
    }
  }
}

.updateBankDeatils {
  z-index: 999999;
  .modal-header {
    background-color: var(--headClr);
    color: var(--whiteClr);
    border: 0;
    button {
      filter: invert(1);
    }
  }
  .modal-body {
    background: #040b1b;
    input {
      background: transparent !important;
      color: var(--whiteClr) !important;
      border: 1px solid var(--whiteClr) !important;
      &::placeholder {
        color: var(--whiteClr);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    button.btn-primary {
      background: rgb(6, 99, 177);
      background: linear-gradient(
        100deg,
        rgba(6, 99, 177, 1) 0%,
        rgba(40, 131, 239, 1) 100%
      );
      border-radius: 5px !important;
      border: 0;
      font-weight: 400;
      height: 35px;
      margin: 0 auto;
      width: 85px;
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.WithdrawGif {
  img {
    animation: moving 3s infinite linear;
    margin-left: 20px;
    width: 365px;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.WithdrawTable tbody tr td input[type="checkbox"] + label {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.2em;
}
.WithdrawTable tbody tr td input[type="checkbox"] {
  display: none;
}
.WithdrawTable tbody tr td input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.WithdrawTable tbody tr td input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}
.WithdrawPageSection
  .WithDrawSection.tabs-area
  .tab-content
  .WithdrawRequestSection
  .tab-content
  input:disabled {
  background: #415059 !important;
  border: 0 !important;
  cursor: no-drop !important;
}

.live_casino_page {
  scroll-snap-type: mandatory;
  &.live {
    background: #000;
    color: var(--whiteClr);
  }
  .ezugi_iframe,
  .exchange_iframe {
    iframe {
      width: 100%;
      height: calc(100vh - 85px);
      @media only screen and (max-width: 767.98px) {
        height: calc(100vh - 76px);
      }
      @media only screen and (min-device-width: 280px) and (max-device-width: 812px) {
        height: calc(100vh - 76px);
        height: calc(var(--vh, 1vh) * 100 - 76px);
      }
    }
  }
}

.casinoProvidersGames {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 0 15px;
    li {
      background-color: transparent;
      display: block;
      height: auto;
      padding: 0;
      position: relative;
      width: calc(14.285% - 8.57px);
      a {
        display: block;
        color: var(--whiteClr);
        padding: 5px;
        background-color: var(--secondaryClr);
        border-radius: 4px;
        img {
          border-radius: 2px;
          width: 100%;
        }
        &:hover {
          .play-btn {
            inset: 0 0 0 0;
            img {
              height: 60px !important;
              width: 60px !important;
            }
          }
        }
        .play-btn {
          align-items: center;
          background: linear-gradient(
            0deg,
            rgb(37 44 61 / 55%),
            rgb(4 11 27 / 42%)
          );
          border-radius: 4px;
          content: "";
          display: flex;
          inset: 0 0 0 100%;
          justify-content: center;
          position: absolute;
          transition: all 0.9s;
          img {
            border-radius: 10px;
            height: 0;
            width: 0 !important;
          }
        }
      }
    }
  }
}

.DemoLogin {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--bodyClr);
  button {
    background: #0f172a !important;
    height: 37px;
    padding: 0 35px;
    font-size: 16px;
    line-height: 1.25rem !important;
    font-weight: 400;
    margin: 0;
    animation: inherit;
    transition: transform 0.2s;
    position: relative;
  }
}

.Announcement {
  position: relative;
  background: linear-gradient(90.01deg, #292f3f 1.44%, #252c3d 113.19%);
  height: 35px;
  .latest {
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #161c29;
    background-color: #151923;
    padding: 5px;
    z-index: 9;
    font-weight: 600;
    img {
      width: 22px;
      height: 22px;
      margin-right: 3px;
    }
  }
  marquee {
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
}

.MaintainSection {
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 40px;
  .MaintainSectionContent {
    width: 50%;

    .MaintainSection__title {
      font-size: 40px;
      line-height: 17px;
    }
    p.maintainword {
      text-transform: uppercase;
      color: var(--primaryClr);
      font-weight: 800;
      font-size: 50px;
      position: relative;
      margin-bottom: 70px;
      &::before {
        width: 55px;
        height: 5px;
        display: block;
        content: "";
        position: absolute;
        bottom: -2px;
        left: 30%;
        margin-left: -14px;
        background-color: var(--primaryClr);
      }
      &::after {
        width: 410px;
        height: 1px;
        display: block;
        content: "";
        position: relative;
        margin-top: 6px;
        left: 82px;
        margin-left: -78px;
        background-color: var(--primaryClr);
      }
    }
    p.backsoon {
      font-size: 40px;
      color: #ffffff;
      line-height: 13px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      font-size: 14px;
    }
  }
  .MaintainImg {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 500px;
    }
  }
}
